import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-150bc5c0"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "card-local" }
const _hoisted_2 = ["src"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("h3", null, _toDisplayString(_ctx.$t('components.embedMap.location')), 1),
    _createElementVNode("iframe", {
      width: "100%",
      height: "250",
      style: {"border":"0"},
      loading: "lazy",
      allowfullscreen: "",
      src: `https://www.google.com/maps/embed/v1/place?key=${_ctx.googleApiKey}&q=${_ctx.jobCity}, ${_ctx.jobLanguage}`
    }, null, 8, _hoisted_2)
  ]))
}