
import moment from 'moment'
import { useHead } from '@vueuse/head'
import { defineComponent, computed } from 'vue'
import MultiStepFormApplyModal from '@/components/ApplyTunnel/MultiStepFormApplyModal.vue'
import EmbedMap from '@/components/EmbedMap/EmbedMap.vue'
import SimilarJobs from '@/components/job/SimilarJobs.vue'
import FrontPageAds from '@/components/job/FrontPageAds.vue'
import ShareButton from '@/components/ShareButton/ShareButton.vue'
import { removeTags } from '../../utils/string'
import { mapState } from 'vuex'
import { formatJobDate } from "@/utils/string";
import {jobs} from "@/store/modules/jobs";
import {client} from "@/store/modules/client";
import TarteAuCitron from "@/components/TarteAuCitron/TarteAuCitron.vue";
import { camelCase } from "@/utils/string";

const __default__ = defineComponent({
  name: 'JobView',
  components: {
    MultiStepFormApplyModal,
    EmbedMap,
    SimilarJobs,
    FrontPageAds,
    ShareButton,
    TarteAuCitron
  },
  data() {
    return {
      dataModal: false,
      job: {} as JobDetailsType,
      ssrJobDetailsUrl: "",
      utm_source: "5gm"
    }
  },

  methods: {
    apply() {
      if (this.kickMyBot) {
        this.startKickMyBot()
      } else {
        this.showModal()
      }
    },
    extractAnSetParameters() {
      const query = this.$route.query;
      if (query.apply === 'true') {
        this.showModal()
      }
      if (query.utm_source) {
        this.utm_source = query.utm_source.toString();
      }
    },
    getJob() {
      this.$store.dispatch('jobs/getJob', this.$route.params.jobId).then(
        (response: JobDetailsType) => {
          this.job = response
          this.ssrJobDetailsUrl = window.location.origin + this.$store.state.client.ssrJobDetailsEndpoint + response.wid + '?design=true'
          this.setOpenGraphTag()
        },
        (error: ResponseType) => {
          this.$router.push('/')
        })
    },
    showModal() {
      this.dataModal = true
    },
    initKickMyBot() {
      if (this.kickMyBot) {
        this.loadScript("https://chat-window.kmblabs.com/", function () {
          //@ts-ignore
          KMBotUI.init({
            projectKey: "eyJhbGciOiJIUzI1NiJ9.eyJwcm9qZWN0TmFtZSI6Ik9ydGVjIn0.66qruLdt3K84Ln-pidxZdZqBrhU2IVhzmpAIi0o88pg",
          });
        });
      }
    },
    loadScript(src, callback) {
      var s,
              r,
              t;
      r = false;
      s = document.createElement('script');
      s.type = 'text/javascript';
      s.src = src;
      s.onload = s.onreadystatechange = function() {
        // console.log( this.readyState ); //uncomment this line to see which ready states are called.
        if ( !r && (!this.readyState || this.readyState == 'complete') ) {
          r = true;
          callback();
        }
      };
      t = document.getElementsByTagName('script')[0];
      t.parentNode.insertBefore(s, t);
    },
    startKickMyBot() {
      dispatchEvent(new CustomEvent("KMBCustomStartMessage", {
        detail: {
          message: {
            title: "Je postule",
            payload: "START_ORTEC_" + this.job.wid + this.utm_source + "_" + this.$i18n.locale,

          }
        }
      }));
    },
    setOpenGraphTag() {
      useHead({
        // Can be static or computed
        meta: [
          {
            property: `og:image`,
            content: computed(() => this.$store.state.client.design.ogLogo),
          },
          {
            property: `og:url`,
            content: computed(() => this.currentUrl),
          },
          {
            property: `og:title`,
            content: computed(() => this.job.title),
          },
          {
            property: `og:description`,
            content: computed(() =>
                    removeTags(this.job.descriptionMission + ' ' + this.job.descriptionProfile)
            ),
          },
        ]
      })
    },
    scrollToTop() {
      let app = document.getElementById('app')
      if (app) {
        app.scrollIntoView({behavior:'auto'})
      }
    },
  },
  mounted() {
    this.getJob()
    this.extractAnSetParameters()
    this.initKickMyBot()
    this.scrollToTop()
  },
  computed: {
    ...mapState(['client']),
    currentUrl() {
      return window.location.href
    },
    kickMyBot() {
      return this.$store.state.client.properties.kickMyBot
    },
  },
  setup() {
    return { formatJobDate, camelCase }
  },
  created() {
    this.$watch(
      () => this.$route.params,
        (newValue) => {
          this.getJob()
          //this.getSimilarJobs()
      }
    )
  }
})

import { useCssVars as _useCssVars } from 'vue'
const __injectCSSVars__ = () => {
_useCssVars(_ctx => ({
  "67d7f8da": (_ctx.client.design.primaryColor),
  "20b20a3e": (_ctx.client.design.secondaryColor)
}))}
const __setup__ = __default__.setup
__default__.setup = __setup__
  ? (props, ctx) => { __injectCSSVars__();return __setup__(props, ctx) }
  : __injectCSSVars__

export default __default__