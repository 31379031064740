import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, normalizeStyle as _normalizeStyle, withCtx as _withCtx, vShow as _vShow, withDirectives as _withDirectives, createTextVNode as _createTextVNode, openBlock as _openBlock, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-59620f9c"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "p-3 pb-0" }
const _hoisted_2 = ["src"]
const _hoisted_3 = { class: "d-flex ml-2 flex-column" }
const _hoisted_4 = { class: "mb-2 ml-sm-2 text-center" }
const _hoisted_5 = { class: "d-flex flex-column flex-sm-row align-center align-sm-start" }
const _hoisted_6 = { class: "spinner-border spinner-border-sm" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_v_btn = _resolveComponent("v-btn")!
  const _component_v_card_title = _resolveComponent("v-card-title")!
  const _component_v_container = _resolveComponent("v-container")!
  const _component_v_card_text = _resolveComponent("v-card-text")!
  const _component_v_card = _resolveComponent("v-card")!
  const _component_v_dialog = _resolveComponent("v-dialog")!

  return (_openBlock(), _createBlock(_component_v_dialog, {
    modelValue: _ctx.show,
    "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.show) = $event)),
    "max-width": "800px"
  }, {
    default: _withCtx(() => [
      _createVNode(_component_v_card, null, {
        default: _withCtx(() => [
          _createVNode(_component_v_card_title, {
            class: "d-flex justify-content-between",
            style: _normalizeStyle({ background: _ctx.primaryColor, color: 'white'})
          }, {
            default: _withCtx(() => [
              _createElementVNode("h2", _hoisted_1, _toDisplayString(_ctx.$t('page.user.profile.picture.form.title')), 1),
              _createVNode(_component_v_btn, {
                right: "",
                icon: "mdi-close",
                variant: "text",
                onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.show = false))
              })
            ]),
            _: 1
          }, 8, ["style"]),
          _createVNode(_component_v_card_text, null, {
            default: _withCtx(() => [
              _createVNode(_component_v_container, { class: "d-flex align-items-center justify-content-center flex-column flex-sm-row mb-2" }, {
                default: _withCtx(() => [
                  _createElementVNode("img", {
                    class: "img-account-profile rounded-circle mr-2",
                    src: _ctx.profilePicture,
                    alt: ""
                  }, null, 8, _hoisted_2),
                  _createElementVNode("div", _hoisted_3, [
                    _createElementVNode("div", _hoisted_4, [
                      _createElementVNode("span", null, _toDisplayString(_ctx.$t('page.user.profile.picture.form.details')), 1)
                    ]),
                    _createElementVNode("div", _hoisted_5, [
                      _createElementVNode("div", null, [
                        _createElementVNode("input", {
                          type: "file",
                          id: "profilePictureInput",
                          ref: "profilePictureInput",
                          style: {"display":"none"},
                          onChange: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.uploadProfilePicture && _ctx.uploadProfilePicture(...args)))
                        }, null, 544),
                        _createVNode(_component_v_btn, {
                          class: "btn btn-primary mr-sm-2 mb-2 mb-sm-0",
                          color: _ctx.primaryColor,
                          style: _normalizeStyle({ 'background-color': _ctx.primaryColor + '!important', color: 'white'}),
                          disabled: _ctx.loading,
                          onClick: _cache[2] || (_cache[2] = ($event: any) => (_ctx.$refs.profilePictureInput.click()))
                        }, {
                          default: _withCtx(() => [
                            _withDirectives(_createElementVNode("span", _hoisted_6, null, 512), [
                              [_vShow, _ctx.loading]
                            ]),
                            _createTextVNode(" " + _toDisplayString(_ctx.$t('page.user.profile.picture.form.upload')), 1)
                          ]),
                          _: 1
                        }, 8, ["color", "style", "disabled"])
                      ]),
                      _createVNode(_component_v_btn, {
                        class: "",
                        color: _ctx.primaryColor,
                        variant: "outlined",
                        onClick: _cache[3] || (_cache[3] = ($event: any) => (_ctx.delete)),
                        disabled: _ctx.loading
                      }, {
                        default: _withCtx(() => [
                          _createTextVNode(_toDisplayString(_ctx.$t('page.user.profile.picture.form.delete')), 1)
                        ]),
                        _: 1
                      }, 8, ["color", "disabled"])
                    ])
                  ])
                ]),
                _: 1
              })
            ]),
            _: 1
          })
        ]),
        _: 1
      })
    ]),
    _: 1
  }, 8, ["modelValue"]))
}