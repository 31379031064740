import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, createTextVNode as _createTextVNode, withModifiers as _withModifiers, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-0fbcf930"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "text-center" }
const _hoisted_2 = { class: "mb-2" }
const _hoisted_3 = { key: 0 }
const _hoisted_4 = {
  key: 0,
  class: "alert alert-success",
  role: "alert"
}
const _hoisted_5 = { key: 1 }
const _hoisted_6 = {
  key: 0,
  class: "alert alert-danger",
  role: "alert"
}
const _hoisted_7 = { class: "mt-3 text-center" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_v_text_field = _resolveComponent("v-text-field")!
  const _component_v_row = _resolveComponent("v-row")!
  const _component_v_btn = _resolveComponent("v-btn")!
  const _component_v_form = _resolveComponent("v-form")!
  const _component_v_card = _resolveComponent("v-card")!
  const _component_v_col = _resolveComponent("v-col")!
  const _component_v_container = _resolveComponent("v-container")!

  return (_openBlock(), _createBlock(_component_v_container, {
    fluid: "",
    class: "fill-height"
  }, {
    default: _withCtx(() => [
      _createVNode(_component_v_row, {
        align: "center",
        justify: "center",
        dense: ""
      }, {
        default: _withCtx(() => [
          _createVNode(_component_v_col, {
            cols: "12",
            sm: "8",
            md: "4",
            lg: "4"
          }, {
            default: _withCtx(() => [
              _createVNode(_component_v_card, {
                elevation: "1",
                class: "p-4 pt-5 pb-5"
              }, {
                default: _withCtx(() => [
                  _createElementVNode("div", _hoisted_1, [
                    _createElementVNode("h1", _hoisted_2, _toDisplayString(_ctx.$t('page.forgot_password.form.title')), 1),
                    _createElementVNode("p", null, _toDisplayString(_ctx.$t('page.forgot_password.form.description')), 1)
                  ]),
                  (_ctx.status == 'Success')
                    ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
                        (_ctx.message)
                          ? (_openBlock(), _createElementBlock("div", _hoisted_4, _toDisplayString(_ctx.message), 1))
                          : _createCommentVNode("", true)
                      ]))
                    : (_openBlock(), _createElementBlock("div", _hoisted_5, [
                        (_ctx.message)
                          ? (_openBlock(), _createElementBlock("div", _hoisted_6, _toDisplayString(_ctx.message), 1))
                          : _createCommentVNode("", true)
                      ])),
                  _createVNode(_component_v_form, {
                    onSubmit: _withModifiers(_ctx.submitForm, ["prevent"]),
                    class: "mt-10",
                    "lazy-validation": ""
                  }, {
                    default: _withCtx(() => [
                      _createVNode(_component_v_row, { class: "mt-2 p-2" }, {
                        default: _withCtx(() => [
                          _createVNode(_component_v_text_field, {
                            label: _ctx.$t('page.forgot_password.form.username.placeholder'),
                            name: "email",
                            "prepend-inner-icon": "mdi-email-outline",
                            type: "email",
                            modelValue: _ctx.email,
                            "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.email) = $event)),
                            variant: "outlined"
                          }, null, 8, ["label", "modelValue"])
                        ]),
                        _: 1
                      }),
                      _createElementVNode("div", _hoisted_7, [
                        _createVNode(_component_v_btn, {
                          color: _ctx.white,
                          class: "btn btn-primary",
                          required: "",
                          type: "submit",
                          disabled: !_ctx.isEmailValid
                        }, {
                          default: _withCtx(() => [
                            _createTextVNode(_toDisplayString(_ctx.$t('page.forgot_password.form.reset_password')), 1)
                          ]),
                          _: 1
                        }, 8, ["color", "disabled"])
                      ])
                    ]),
                    _: 1
                  }, 8, ["onSubmit"])
                ]),
                _: 1
              })
            ]),
            _: 1
          })
        ]),
        _: 1
      })
    ]),
    _: 1
  }))
}