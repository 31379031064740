import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, createTextVNode as _createTextVNode, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-4bcbb280"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "p-3 pb-0 text-center" }
const _hoisted_2 = {
  key: 0,
  class: "alert alert-danger",
  role: "alert"
}
const _hoisted_3 = { class: "mt-3 text-center" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_v_text_field = _resolveComponent("v-text-field")!
  const _component_v_row = _resolveComponent("v-row")!
  const _component_v_btn = _resolveComponent("v-btn")!
  const _component_v_form = _resolveComponent("v-form")!
  const _component_v_card = _resolveComponent("v-card")!
  const _component_v_col = _resolveComponent("v-col")!
  const _component_v_container = _resolveComponent("v-container")!

  return (_openBlock(), _createBlock(_component_v_container, {
    fluid: "",
    class: "fill-height"
  }, {
    default: _withCtx(() => [
      _createVNode(_component_v_row, {
        align: "center",
        justify: "center",
        dense: ""
      }, {
        default: _withCtx(() => [
          _createVNode(_component_v_col, {
            cols: "12",
            sm: "8",
            md: "4",
            lg: "4"
          }, {
            default: _withCtx(() => [
              _createVNode(_component_v_card, { class: "elevation-1 mb-2 mt-2 p-3" }, {
                default: _withCtx(() => [
                  _createElementVNode("h1", _hoisted_1, _toDisplayString(_ctx.$t('page.reset_password.form.title')), 1),
                  (_ctx.error)
                    ? (_openBlock(), _createElementBlock("div", _hoisted_2, _toDisplayString(_ctx.message), 1))
                    : _createCommentVNode("", true),
                  _createVNode(_component_v_form, { class: "mt-10" }, {
                    default: _withCtx(() => [
                      _createVNode(_component_v_row, { class: "mt-2 p-2" }, {
                        default: _withCtx(() => [
                          _createVNode(_component_v_text_field, {
                            modelValue: _ctx.newPassword,
                            "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.newPassword) = $event)),
                            label: _ctx.$t('page.reset_password.form.password.label'),
                            "prepend-inner-icon": "mdi-lock-outline",
                            type: "password",
                            rules: [_ctx.required, _ctx.min6],
                            variant: "outlined",
                            class: "mt-2"
                          }, null, 8, ["modelValue", "label", "rules"])
                        ]),
                        _: 1
                      }),
                      _createVNode(_component_v_row, { class: "mt-2 p-2" }, {
                        default: _withCtx(() => [
                          _createVNode(_component_v_text_field, {
                            modelValue: _ctx.confirmPassword,
                            "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.confirmPassword) = $event)),
                            label: _ctx.$t('page.reset_password.form.confirmPassword.label'),
                            "prepend-inner-icon": "mdi-lock-outline",
                            type: "password",
                            rules: [_ctx.required, _ctx.min6, _ctx.matchingPasswords],
                            variant: "outlined",
                            class: "mt-2"
                          }, null, 8, ["modelValue", "label", "rules"])
                        ]),
                        _: 1
                      }),
                      _createElementVNode("div", _hoisted_3, [
                        _createVNode(_component_v_btn, {
                          onClick: _ctx.submitForm,
                          color: _ctx.primaryColor,
                          class: "btn btn-primary",
                          required: "",
                          disabled: !_ctx.passRule
                        }, {
                          default: _withCtx(() => [
                            _createTextVNode(_toDisplayString(_ctx.$t('page.reset_password.form.save')), 1)
                          ]),
                          _: 1
                        }, 8, ["onClick", "color", "disabled"])
                      ])
                    ]),
                    _: 1
                  })
                ]),
                _: 1
              })
            ]),
            _: 1
          })
        ]),
        _: 1
      })
    ]),
    _: 1
  }))
}