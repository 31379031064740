import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, vShow as _vShow, withDirectives as _withDirectives, createTextVNode as _createTextVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, withCtx as _withCtx, normalizeClass as _normalizeClass, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-7f80ef62"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "col-md-12" }
const _hoisted_2 = { class: "card card-container" }
const _hoisted_3 = { style: {"text-align":"center"} }
const _hoisted_4 = { key: 0 }
const _hoisted_5 = { class: "form-group" }
const _hoisted_6 = { for: "newPassword" }
const _hoisted_7 = { class: "form-group" }
const _hoisted_8 = { for: "confirmNewPassword" }
const _hoisted_9 = { class: "form-group" }
const _hoisted_10 = ["disabled"]
const _hoisted_11 = { class: "spinner-border spinner-border-sm" }
const _hoisted_12 = { key: 1 }
const _hoisted_13 = { class: "form-group" }
const _hoisted_14 = ["disabled"]
const _hoisted_15 = { class: "spinner-border spinner-border-sm" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Field = _resolveComponent("Field")!
  const _component_ErrorMessage = _resolveComponent("ErrorMessage")!
  const _component_Form = _resolveComponent("Form")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("h3", _hoisted_3, _toDisplayString(_ctx.$t('page.signup.form.title')), 1),
      _createVNode(_component_Form, {
        onSubmit: _ctx.handleValidation,
        "validation-schema": _ctx.schema
      }, {
        default: _withCtx(() => [
          (!_ctx.tokenExpired && !_ctx.successful)
            ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
                _createElementVNode("div", _hoisted_5, [
                  _createElementVNode("label", _hoisted_6, _toDisplayString(_ctx.$t('page.validation.form.password.label')), 1),
                  _createVNode(_component_Field, {
                    name: "newPassword",
                    type: "password",
                    class: "form-control",
                    placeholder: _ctx.$t('page.validation.form.password.placeholder'),
                    style: {"margin-bottom":"16px"}
                  }, null, 8, ["placeholder"]),
                  _createVNode(_component_ErrorMessage, {
                    name: "newPassword",
                    class: "error-feedback"
                  })
                ]),
                _createElementVNode("div", _hoisted_7, [
                  _createElementVNode("label", _hoisted_8, _toDisplayString(_ctx.$t('page.validation.form.confirmPassword.label')), 1),
                  _createVNode(_component_Field, {
                    name: "confirmNewPassword",
                    type: "password",
                    class: "form-control",
                    placeholder: 
                _ctx.$t('page.validation.form.confirmPassword.placeholder')
              ,
                    style: {"margin-bottom":"16px"}
                  }, null, 8, ["placeholder"]),
                  _createVNode(_component_ErrorMessage, {
                    name: "confirmNewPassword",
                    class: "error-feedback"
                  })
                ]),
                _createElementVNode("div", _hoisted_9, [
                  _createElementVNode("button", {
                    class: "btn btn-primary btn-block",
                    disabled: _ctx.loading
                  }, [
                    _withDirectives(_createElementVNode("span", _hoisted_11, null, 512), [
                      [_vShow, _ctx.loading]
                    ]),
                    _createTextVNode(" " + _toDisplayString(_ctx.$t('page.validation.form.validate')), 1)
                  ], 8, _hoisted_10)
                ])
              ]))
            : _createCommentVNode("", true)
        ]),
        _: 1
      }, 8, ["onSubmit", "validation-schema"]),
      (_ctx.message)
        ? (_openBlock(), _createElementBlock("div", {
            key: 0,
            class: _normalizeClass(["alert", _ctx.successful ? 'alert-success' : 'alert-danger'])
          }, _toDisplayString(_ctx.message), 3))
        : _createCommentVNode("", true),
      (_ctx.tokenExpired)
        ? (_openBlock(), _createElementBlock("div", _hoisted_12, [
            _createElementVNode("div", _hoisted_13, [
              _createElementVNode("button", {
                class: "btn btn-primary btn-block",
                disabled: _ctx.loading,
                onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.handleResendToken && _ctx.handleResendToken(...args)))
              }, [
                _withDirectives(_createElementVNode("span", _hoisted_15, null, 512), [
                  [_vShow, _ctx.loading]
                ]),
                _createTextVNode(" " + _toDisplayString(_ctx.$t('page.validation.form.resendUserToken')), 1)
              ], 8, _hoisted_14)
            ]),
            (_ctx.tokenMessage)
              ? (_openBlock(), _createElementBlock("div", {
                  key: 0,
                  class: _normalizeClass(["alert", _ctx.successful ? 'alert-success' : 'alert-danger'])
                }, _toDisplayString(_ctx.$t(_ctx.tokenMessage)), 3))
              : _createCommentVNode("", true)
          ]))
        : _createCommentVNode("", true)
    ])
  ]))
}