import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-05a1e2a8"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "d-flex p-3 justify-content-between" }
const _hoisted_2 = { class: "d-flex align-items-center justify-content-center security-action" }
const _hoisted_3 = { class: "mr-2" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_v_text_field = _resolveComponent("v-text-field")!
  const _component_v_btn = _resolveComponent("v-btn")!
  const _component_v_card_text = _resolveComponent("v-card-text")!
  const _component_v_card = _resolveComponent("v-card")!
  const _component_UpdatePasswordForm = _resolveComponent("UpdatePasswordForm")!
  const _component_UpdateEmailForm = _resolveComponent("UpdateEmailForm")!
  const _component_DeleteMyAccount = _resolveComponent("DeleteMyAccount")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_v_card, { class: "elevation-1 mb-8 rounded-lg security-content card" }, {
      default: _withCtx(() => [
        _createElementVNode("div", _hoisted_1, [
          _createElementVNode("h2", null, _toDisplayString(_ctx.$t('components.security.title')), 1),
          _createElementVNode("div", {
            class: "btn-delete-account",
            onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.showDeleteMyAccount = true))
          }, _toDisplayString(_ctx.$t('components.security.buttons.deleteAccount')), 1)
        ]),
        _createVNode(_component_v_card_text, { class: "pt-0 mb-4" }, {
          default: _withCtx(() => [
            _createVNode(_component_v_text_field, {
              density: "compact",
              id: "currentUsername",
              name: "currentUsername",
              type: "email",
              variant: "outlined",
              label: _ctx.username,
              disabled: ""
            }, null, 8, ["label"]),
            _createVNode(_component_v_text_field, {
              density: "compact",
              id: "currentPassword",
              name: "currentPassword",
              variant: "outlined",
              type: "password",
              label: 
          _ctx.$t('page.security.form.update_password.password.label')
        ,
              disabled: ""
            }, null, 8, ["label"]),
            _createElementVNode("div", _hoisted_2, [
              _createElementVNode("span", _hoisted_3, _toDisplayString(_ctx.$t('components.security.buttons.update')), 1),
              _createVNode(_component_v_btn, {
                class: "btn btn-primary btn-action mr-2",
                type: "button",
                size: "small",
                onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.showUpdateEmailForm = true))
              }, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(_ctx.$t('components.security.buttons.updateEmail')), 1)
                ]),
                _: 1
              }),
              _createVNode(_component_v_btn, {
                class: "btn btn-primary btn-action",
                type: "button",
                size: "small",
                onClick: _cache[2] || (_cache[2] = ($event: any) => (_ctx.showUpdatePasswordForm = true))
              }, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(_ctx.$t('components.security.buttons.updatePassword')), 1)
                ]),
                _: 1
              })
            ])
          ]),
          _: 1
        })
      ]),
      _: 1
    }),
    _createVNode(_component_UpdatePasswordForm, {
      visible: _ctx.showUpdatePasswordForm,
      onClose: _cache[3] || (_cache[3] = ($event: any) => (_ctx.showUpdatePasswordForm = false))
    }, null, 8, ["visible"]),
    _createVNode(_component_UpdateEmailForm, {
      visible: _ctx.showUpdateEmailForm,
      onClose: _cache[4] || (_cache[4] = ($event: any) => (_ctx.showUpdateEmailForm = false))
    }, null, 8, ["visible"]),
    _createVNode(_component_DeleteMyAccount, {
      visible: _ctx.showDeleteMyAccount,
      onClose: _cache[5] || (_cache[5] = ($event: any) => (_ctx.showDeleteMyAccount = false))
    }, null, 8, ["visible"])
  ], 64))
}