import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, vShow as _vShow, withDirectives as _withDirectives, createTextVNode as _createTextVNode, withModifiers as _withModifiers, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-104a3614"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "p-3 pb-0 text-center" }
const _hoisted_2 = {
  key: 0,
  class: "alert alert-danger",
  role: "alert"
}
const _hoisted_3 = { class: "mt-3 text-center" }
const _hoisted_4 = { class: "spinner-border spinner-border-sm mr-2" }
const _hoisted_5 = { class: "mt-2 text-center" }
const _hoisted_6 = { class: "mt-2 text-center" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_v_text_field = _resolveComponent("v-text-field")!
  const _component_v_row = _resolveComponent("v-row")!
  const _component_v_btn = _resolveComponent("v-btn")!
  const _component_v_form = _resolveComponent("v-form")!
  const _component_router_link = _resolveComponent("router-link")!
  const _component_v_card_text = _resolveComponent("v-card-text")!
  const _component_v_card = _resolveComponent("v-card")!
  const _component_v_col = _resolveComponent("v-col")!
  const _component_v_container = _resolveComponent("v-container")!

  return (_openBlock(), _createBlock(_component_v_container, {
    fluid: "",
    class: "fill-height"
  }, {
    default: _withCtx(() => [
      _createVNode(_component_v_row, {
        align: "center",
        justify: "center",
        dense: ""
      }, {
        default: _withCtx(() => [
          _createVNode(_component_v_col, {
            cols: "12",
            sm: "8",
            md: "4",
            lg: "4"
          }, {
            default: _withCtx(() => [
              _createVNode(_component_v_card, { class: "elevation-1 mb-2 mt-2 p-3" }, {
                default: _withCtx(() => [
                  _createElementVNode("h1", _hoisted_1, _toDisplayString(_ctx.$t('page.login.form.title')), 1),
                  _createVNode(_component_v_card_text, null, {
                    default: _withCtx(() => [
                      (_ctx.message)
                        ? (_openBlock(), _createElementBlock("div", _hoisted_2, _toDisplayString(_ctx.message), 1))
                        : _createCommentVNode("", true),
                      _createVNode(_component_v_form, {
                        ref: "loginForm",
                        onSubmit: _withModifiers(_ctx.validate, ["prevent"]),
                        modelValue: _ctx.valid,
                        "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.valid) = $event)),
                        "lazy-validation": ""
                      }, {
                        default: _withCtx(() => [
                          _createVNode(_component_v_row, { class: "mt-2 p-2" }, {
                            default: _withCtx(() => [
                              _createVNode(_component_v_text_field, {
                                modelValue: _ctx.username,
                                "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.username) = $event)),
                                "prepend-inner-icon": "mdi-email-outline",
                                name: "username",
                                variant: "outlined",
                                rules: _ctx.usernameRule,
                                label: _ctx.$t('page.login.form.username.label'),
                                required: ""
                              }, null, 8, ["modelValue", "rules", "label"])
                            ]),
                            _: 1
                          }),
                          _createVNode(_component_v_row, { class: "mt-2 p-2" }, {
                            default: _withCtx(() => [
                              _createVNode(_component_v_text_field, {
                                modelValue: _ctx.password,
                                "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.password) = $event)),
                                name: "password",
                                "prepend-inner-icon": "mdi-lock-outline",
                                type: "password",
                                variant: "outlined",
                                rules: _ctx.passwordRule,
                                label: _ctx.$t('page.login.form.password.label'),
                                required: ""
                              }, null, 8, ["modelValue", "rules", "label"])
                            ]),
                            _: 1
                          }),
                          _createElementVNode("div", _hoisted_3, [
                            _createVNode(_component_v_btn, {
                              type: "submit",
                              class: "btn btn-primary"
                            }, {
                              default: _withCtx(() => [
                                _withDirectives(_createElementVNode("span", _hoisted_4, null, 512), [
                                  [_vShow, _ctx.loading]
                                ]),
                                _createTextVNode(" " + _toDisplayString(_ctx.$t('page.login.form.login')), 1)
                              ]),
                              _: 1
                            })
                          ])
                        ]),
                        _: 1
                      }, 8, ["onSubmit", "modelValue"]),
                      _createElementVNode("div", _hoisted_5, [
                        _createVNode(_component_router_link, {
                          class: "link",
                          to: { path: '/forgot-password' }
                        }, {
                          default: _withCtx(() => [
                            _createTextVNode(_toDisplayString(_ctx.$t('page.login.form.forgot_password')), 1)
                          ]),
                          _: 1
                        })
                      ]),
                      _createElementVNode("div", _hoisted_6, [
                        _createTextVNode(_toDisplayString(_ctx.$t('page.login.form.no_account')) + " ", 1),
                        _createVNode(_component_router_link, {
                          class: "link",
                          to: { path: '/register' }
                        }, {
                          default: _withCtx(() => [
                            _createTextVNode(_toDisplayString(_ctx.$t('page.login.form.no_account_here')), 1)
                          ]),
                          _: 1
                        })
                      ])
                    ]),
                    _: 1
                  })
                ]),
                _: 1
              })
            ]),
            _: 1
          })
        ]),
        _: 1
      })
    ]),
    _: 1
  }))
}