import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-74e255d4"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "flex p-0 offer offer-section h-100 section-dashboard" }
const _hoisted_2 = { class: "offer-container h-100 container pt-8" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Profile = _resolveComponent("Profile")!
  const _component_Document = _resolveComponent("Document")!
  const _component_Security = _resolveComponent("Security")!
  const _component_v_col = _resolveComponent("v-col")!
  const _component_History = _resolveComponent("History")!
  const _component_v_row = _resolveComponent("v-row")!

  return (_openBlock(), _createElementBlock("section", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createVNode(_component_v_row, null, {
        default: _withCtx(() => [
          _createVNode(_component_v_col, {
            cols: "12",
            sm: "12",
            md: "4",
            class: "offer-details"
          }, {
            default: _withCtx(() => [
              _createVNode(_component_Profile),
              _createVNode(_component_Document),
              _createVNode(_component_Security)
            ]),
            _: 1
          }),
          _createVNode(_component_v_col, {
            cols: "12",
            sm: "12",
            md: "8",
            class: "offer-details"
          }, {
            default: _withCtx(() => [
              _createVNode(_component_History)
            ]),
            _: 1
          })
        ]),
        _: 1
      })
    ])
  ]))
}