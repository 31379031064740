import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, normalizeStyle as _normalizeStyle, withCtx as _withCtx, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-61d13540"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "p-3 pb-0" }
const _hoisted_2 = {
  key: 0,
  class: "alert alert-danger mt-3",
  role: "alert"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_v_btn = _resolveComponent("v-btn")!
  const _component_v_card_title = _resolveComponent("v-card-title")!
  const _component_v_container = _resolveComponent("v-container")!
  const _component_v_card_text = _resolveComponent("v-card-text")!
  const _component_v_card_actions = _resolveComponent("v-card-actions")!
  const _component_v_card = _resolveComponent("v-card")!
  const _component_v_dialog = _resolveComponent("v-dialog")!

  return (_openBlock(), _createBlock(_component_v_dialog, {
    modelValue: _ctx.show,
    "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.show) = $event)),
    "max-width": "800px"
  }, {
    default: _withCtx(() => [
      _createVNode(_component_v_card, null, {
        default: _withCtx(() => [
          _createVNode(_component_v_card_title, {
            class: "d-flex justify-content-between",
            style: _normalizeStyle({ background: _ctx.primaryColor, color: 'white'})
          }, {
            default: _withCtx(() => [
              _createElementVNode("h2", _hoisted_1, _toDisplayString(_ctx.$t('page.user.security.deleteMyAccount.form.title')), 1),
              _createVNode(_component_v_btn, {
                right: "",
                icon: "mdi-close",
                variant: "text",
                onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.show = false))
              })
            ]),
            _: 1
          }, 8, ["style"]),
          _createVNode(_component_v_card_text, null, {
            default: _withCtx(() => [
              _createVNode(_component_v_container, { class: "text-center" }, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(_ctx.$t('page.user.security.deleteMyAccount.form.content')) + " ", 1),
                  (_ctx.error)
                    ? (_openBlock(), _createElementBlock("div", _hoisted_2, _toDisplayString(_ctx.message), 1))
                    : _createCommentVNode("", true)
                ]),
                _: 1
              })
            ]),
            _: 1
          }),
          _createVNode(_component_v_card_actions, { class: "d-flex flex-column flex-sm-row align-center align-sm-center justify-center" }, {
            default: _withCtx(() => [
              _createVNode(_component_v_btn, {
                class: "mb-5",
                color: _ctx.primaryColor,
                variant: "outlined",
                onClick: _ctx.submit,
                disabled: _ctx.loading
              }, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(_ctx.$t('page.user.security.deleteMyAccount.form.submit')), 1)
                ]),
                _: 1
              }, 8, ["color", "onClick", "disabled"]),
              _createVNode(_component_v_btn, {
                type: "submit",
                class: "mr-4 mb-5 btn btn-primary",
                style: _normalizeStyle({ 'background-color': _ctx.primaryColor + '!important', color: 'white'}),
                onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.show = false))
              }, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(_ctx.$t('page.user.security.deleteMyAccount.form.close')), 1)
                ]),
                _: 1
              }, 8, ["style"])
            ]),
            _: 1
          })
        ]),
        _: 1
      })
    ]),
    _: 1
  }, 8, ["modelValue"]))
}